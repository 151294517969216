import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [loading, setLoading] = useState(true);
  const [domainName, setDomainName] = useState('');
  const [domainDescription, setDomainDescription] = useState('');
  const [domainPrice, setDomainPrice] = useState('');

  const handleWhatsAppClick = () => {
    const message = encodeURIComponent(`Halo, Saya ingin membeli domain yang Anda jual\n\nDomain: vpn-remote.my.id\nHarga pada Website: Rp 1.000.000`);
    window.open(`https://wa.me/6285163006316/?text=${message}`, '_blank');
  };
  

  useEffect(() => {
    // Simulasi pengambilan data domain dari server (asumsi menggunakan setTimeout)
    setTimeout(() => {
      const domain = window.location.hostname;
    setDomainName(domain);
    
    // Mendapatkan deskripsi domain berdasarkan domain yang diakses
    let description = '';
    switch (domain) {
      case 'vpn-remote.my.id':
        description = 'Domain ini cocok untuk berjualan VPN yang bertujuan untuk meremote jaringan RT/RW Net, Mikrotik, dan perangkat lainnya.';
        break;
      case 'ip-publik.my.id':
        description = 'Domain ini adalah domain alternatif yang juga cocok untuk berjualan VPN.';
        break;
      case 'statics.my.id':
        description = 'Domain ini cocok untuk berjualan VPN yang bertujuan untuk meremote Mikrotik atau perangkat lainnya pada jaringan lokal dari internet.'
        break;
      default:
        description = 'Deskripsi domain untuk domain lainnya.';
        break;
    }
    setDomainDescription(description);
      setDomainPrice('Rp 1.000.000 <span style="color: red; font-style: italic;">*Negotiable</span>');
      setLoading(false);
    }, 1); // Mengatur waktu loading selama 2 detik (2000 ms)
  }, []);

  return (
    <div className="container">
      <header className={loading ? 'fade-in' : 'fade-in active'}>
        <h1>Domain For Sale</h1>
      </header>
      <main className={loading ? 'fade-in' : 'fade-in active'}>
        <div className="domain-info">
          <h2>{domainName}</h2>
          <p>{domainDescription}</p>
          <p dangerouslySetInnerHTML={{ __html: domainPrice }}></p>
        </div>
        <button onClick={handleWhatsAppClick}>
          <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp Logo" className="button-icon" />
          Hubungi Saya via WhatsApp
        </button>
      </main>
      <div className={loading ? 'fade-in active' : 'fade-in'}>
      </div>
    </div>
  );
}

export default App;
